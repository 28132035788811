import React, { useState, useEffect } from "react"
import { Router } from "@reach/router"
import loadable from "@loadable/component"
import { navigate } from "gatsby"
import difference from "lodash/difference"

import SuspenseLoading from "../components/SuspenseLoading"
import SEO from "../components/seo"
import { connect } from "react-redux"
import {
  addCheckoutProducts,
  removeCheckoutProducts,
  setCheckoutLoading,
  setCheckoutRestrictions,
  setPickupConfirmedDate,
  setPickupLocation,
} from "../actions/checkout/setCheckPage"

const CheckoutPage = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../components/CheckoutPage/CheckoutPage"
    ),
  { fallback: <SuspenseLoading /> }
)
const CartPage = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../components/CheckoutPage/Mobile/CartPage"
    ),
  { fallback: <SuspenseLoading /> }
)
const PickupPage = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../components/CheckoutPage/Mobile/PickupPage"
    ),
  { fallback: <SuspenseLoading /> }
)

const Checkout = ({
  dispatch,
  cart,
  locations,
  preorders,
  unavailableLocations,
}) => {
  const [key, setKey] = useState(Date.now())
  const [prevLineItemIds, setPrevLineItemIds] = useState(null)

  useEffect(() => {
    return () => {
      dispatch(setPickupConfirmedDate(null))
      dispatch(setPickupLocation(null))
      setKey(Date.now())
    }
  }, [])

  //Timeout if customer takes too long. In case they leave the order up overnight.
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.confirm(
        "You have been inactive for too long! Your cart needs to be refreshed."
      )

      window.location.reload()
    }, 1800000)

    return () => {
      console.log("Timeout cleared")
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (cart && Object.keys(locations).length > 0) {
      //Prevent excess calculating
      const lineItemIds = JSON.stringify(cart.lineItems.map(cv => cv.id))
      // console.log(lineItemIds, prevLineItemIds)

      if (lineItemIds !== prevLineItemIds) {
        dispatch(setCheckoutLoading(true))

        setPrevLineItemIds(lineItemIds)
        const productIds = []
        const toDispatch = {
          add: [],
          remove: [],
        }

        for (const lineItem of cart.lineItems) {
          if (lineItem.variant === null) {
            localStorage.removeItem("checkout_id")
            navigate("/lineitem-change")
          } else {
            const { id: productId, handle } = lineItem.variant.product

            productIds.push(productId)
            //Only call dispatch if the product is not already in the preorders list in redux. To prevent excess calls.
            if (!preorders[productId]) {
              // dispatch(addCheckoutProducts(handle))
              toDispatch.add.push(handle)
            }
          }
        }

        //Remove any existing preorder restrictions that may be old.
        const delta = [
          ...difference(Object.keys(preorders), productIds),
          ...difference(Object.keys(unavailableLocations.items), productIds),
        ]

        for (const id of delta) {
          // dispatch(removeCheckoutProducts(id))
          toDispatch.remove.push(id)
        }

        dispatch(
          setCheckoutRestrictions({ data: { ...toDispatch, productIds } })
        )
      }
    }
  }, [cart, locations])

  return (
    <>
      <SEO title="Cart" />

      <Router basepath="/checkout">
        <CheckoutPage key={key} path="/" />
        <CartPage path="/cart" />
        <PickupPage path="/pickup" />
      </Router>
    </>
  )
}

const mapStateToProps = ({
  cart,
  checkoutPage: { preorders, unavailableLocations },
  stores: { locations },
}) => {
  return { cart, locations, preorders, unavailableLocations }
}

export default connect(mapStateToProps)(Checkout)
